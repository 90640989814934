"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const imgCache = {};
function importAll(r) {
    r.keys().forEach((key) => (imgCache[key] = r(key).default));
}
//@ts-ignore
importAll(require.context('./assets', false, /\.(svg|webp)$/));
class PerimetercalculatorCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.imgCache = imgCache;
        this.kind = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{"kind": "square"}').kind;
        this.subKind = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{"kind": "square"}').subKind;
        this.values = {};
        this.hasError = false;
        this.$scope.$watch(() => {
            return [this.kind, this.subKind];
        }, (value) => {
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify({
                kind: value[0],
                subKind: value[1],
            }));
        }, true);
    }
    setKind(kind, subKind) {
        this.kind = kind;
        if (this.kind == 'triangle') {
            //@ts-ignore
            this.subKind = ['sss', 'sas', 'asa'].indexOf(subKind) != -1 ? subKind : 'sss';
        }
        else if (this.kind == 'parallelogram') {
            //@ts-ignore
            this.subKind = ['sides', 'one_side&diagonals', 'base_height'].indexOf(subKind) != -1 ? subKind : 'sides';
        }
        else if (this.kind == 'rhombus') {
            //@ts-ignore
            this.subKind = ['side', 'diagonals'].indexOf(subKind) != -1 ? subKind : 'side';
        }
        else {
            this.subKind = undefined;
        }
        if (this.perForm)
            this.clear(this.perForm);
    }
    $onInit() {
    }
    angleToRadians(angle) {
        return angle * (Math.PI / 180);
    }
    radianToDegree(radian) {
        return radian * (180 / Math.PI);
    }
    sin(value) {
        return Math.sin(this.angleToRadians(value));
    }
    cos(value) {
        return Math.cos(this.angleToRadians(value));
    }
    submitForm($form) {
        if ($form.$valid && (Object.keys(this.values).length != 0)) {
            const result = {
                values: ng.copy(this.values)
            };
            this.hasError = false;
            try {
                if (this.kind == 'square') {
                    result.perimeter = this.values.a * 4;
                }
                else if (this.kind == 'rectangle') {
                    result.perimeter = (this.values.a + this.values.b) * 2;
                }
                else if (this.kind == 'triangle') {
                    if (this.subKind == 'sss') {
                        result.perimeter = this.values.a + this.values.b + this.values.c;
                    }
                    else if (this.subKind == 'sas') {
                        result.perimeter = this.values.a + this.values.b + Math.sqrt(Math.pow(this.values.a, 2) + Math.pow(this.values.b, 2) - 2 * this.values.a * this.values.b * this.cos(this.values.Y));
                    }
                    else if (this.subKind == 'asa') {
                        result.perimeter = this.values.a + (this.values.a / this.sin(this.values.B + this.values.Y)) * (this.sin(this.values.B) + this.sin(this.values.Y));
                    }
                }
                else if (this.kind == 'circle') {
                    result.perimeter = 2 * Math.PI * this.values.r;
                }
                else if (this.kind == 'semicircle') {
                    result.perimeter = Math.PI * this.values.r + 2 * this.values.r;
                }
                else if (this.kind == 'circle_sector') {
                    result.perimeter = this.values.r * (this.angleToRadians(this.values.A) + 2);
                }
                else if (this.kind == 'ellipse') {
                    result.perimeter = Math.PI * (3 * (this.values.a + this.values.b) - Math.sqrt((3 * this.values.a + this.values.b) * (this.values.a + 3 * this.values.b)));
                }
                else if (this.kind == 'quadrilateral') {
                    result.perimeter = this.values.a + this.values.b + this.values.c + this.values.d;
                }
                else if (this.kind == 'parallelogram') {
                    if (this.subKind == 'sides') {
                        result.perimeter = 2 * (this.values.a + this.values.b);
                    }
                    else if (this.subKind == 'one_side&diagonals') {
                        result.perimeter = 2 * this.values.b + Math.sqrt(2 * Math.pow(this.values.e, 2) + 2 * Math.pow(this.values.f, 2) - 4 * Math.pow(this.values.b, 2));
                    }
                    else if (this.subKind == 'base_height') {
                        result.perimeter = 2 * (this.values.a + this.values.h / this.sin(this.values.A));
                    }
                }
                else if (this.kind == 'rhombus') {
                    if (this.subKind == 'side') {
                        result.perimeter = 4 * this.values.a;
                    }
                    else if (this.subKind == 'diagonals') {
                        result.perimeter = 2 * Math.sqrt(Math.pow(this.values.e, 2) + Math.pow(this.values.f, 2));
                    }
                }
                else if (this.kind == 'kite') {
                    result.perimeter = 2 * (this.values.a + this.values.b);
                }
                else if (this.kind == 'annulus') {
                    result.perimeter = 2 * Math.PI * (this.values.R + this.values.r);
                }
                else if (this.kind == 'regular_poligon') {
                    result.perimeter = this.values.n * this.values.a;
                }
                if (Object.keys(result).indexOf('perimeter') != -1) {
                    if (isNaN(result.perimeter) || result.perimeter == null) {
                        throw "Perimeter is wrong calculated";
                    }
                    this.result = result;
                }
                else {
                    this.result = undefined;
                }
            }
            catch (e) {
                console.error(e);
                this.result = undefined;
                this.hasError = true;
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
        this.values = {};
    }
    copy(id) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), this.result.perimeter);
        }
    }
}
PerimetercalculatorCtrl.$inject = ['$scope', 'ActionService',];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gamePerimetercalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PerimetercalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('perimetercalculator/');
    }]);
